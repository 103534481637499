const structuredContentCellData = (content, bold = false) => ({
  type: "Container",
  version: "1.0.0",
  props: {
    children: [
      {
        type: "Type",
        version: "1.0.0",
        props: { bold, children: content },
      },
    ],
  },
})

export const withMergedCells = {
  columns: [
    { heading: [structuredContentCellData(["Column A"], true)], id: "A" },
    { heading: [structuredContentCellData(["Column B"], true)], id: "B" },
    { heading: [structuredContentCellData(["Column C"], true)], id: "C" },
  ],
  data: [
    {
      A: {
        children: [
          structuredContentCellData({
            type: "Type",
            version: "1.0.0",
            props: {
              children: [
                {
                  type: "Type",
                  version: "1.0.0",
                  props: { bold: true, children: ["One big column"] },
                },
              ],
            },
          }),
        ],
        colspan: 2,
      },
      B: { colspan: 0 },
      C: { colspan: 0 },
    },
    {
      A: { children: [structuredContentCellData(["Value A"])] },
      B: { children: [structuredContentCellData(["Value B"])] },
      C: { children: [structuredContentCellData(["Value C"])] },
    },
    {
      A: { children: [structuredContentCellData(["Another Value A"])] },
      B: { children: [structuredContentCellData(["Another Value B"])] },
      C: { children: null },
    },
  ],
  isStriped: true,
  rowHover: true,
  hasTableHeader: true,
}

export const withEmptyCells = {
  columns: [
    { heading: [structuredContentCellData(["Column A"], true)], id: "A" },
    { heading: [structuredContentCellData(["Column B"], true)], id: "B" },
    { heading: [structuredContentCellData(["Column C"], true)], id: "C" },
  ],
  data: [
    {
      A: { children: [structuredContentCellData(["A1 value"])] },
      B: { children: [structuredContentCellData(["B1 value"])] },
      C: { children: null },
    },
    {
      A: { children: [structuredContentCellData(["A2 value"])] },
      B: { children: [structuredContentCellData(["B2 value"])] },
      C: { children: null },
    },
  ],
  isStriped: true,
  rowHover: true,
  hasTableHeader: true,
}

export const withMergedCellsAndEmptyCells = {
  columns: [
    { heading: [structuredContentCellData(["Column A"], true)], id: "A" },
    { heading: [structuredContentCellData(["Column B"], true)], id: "B" },
    { heading: [structuredContentCellData(["Column C"], true)], id: "C" },
  ],
  data: [...withEmptyCells.data, ...withMergedCells.data],
  isStriped: true,
  rowHover: true,
  hasTableHeader: true,
}
