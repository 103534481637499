import React from "react"
import PropTypes from "prop-types"
import Page, { Footer, Header, HeaderContent } from "@src/components/Page"
import WPTable from "@src/components/StructuredContentRenderer/WPTable"
import { withMergedCellsAndEmptyCells } from "@src/components/StructuredContentRenderer/WPTable/__fixtures__/WPTable.fixtures"
import DefaultSeparator from "./_component_default_separator"

const WPTablePage = () => {
  const title = "WPTable - Test Page"
  const breadcrumb = { page: { title }, slug: "EXAMPLE" }

  return (
    <Page
      title={title}
      header={<Header />}
      headerContent={<HeaderContent title={title} breadcrumb={breadcrumb} />}
      footer={<Footer />}
    >
      <DefaultSeparator />
      <WPTable {...withMergedCellsAndEmptyCells} />
      <DefaultSeparator />
    </Page>
  )
}

WPTablePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default WPTablePage
